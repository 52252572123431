<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'AP3 Notification Management',
      to: { name: 'notification-manager' },
      last: false,
    });
  },
};
</script>

<style></style>
